import { css, Global } from '@emotion/react';
import { Fragment, memo } from 'react';
/**
 * - 讓包裹的版型子塊能夠高度 100%
 * - 該版型若你需要再外面再包多一層 div 或其它 element 的話，則這些多包的 element 也須要自己配置 height: 100%
 */
export const LayoutGlobalCSSSetup = memo(function LayoutGlobalCSSSetup() {
    return (<Fragment>
      <Global styles={css `
          html,
          body,
          #__next {
            height: 100%;
          }
        `}></Global>
    </Fragment>);
});
