import { css } from '@emotion/react';
import { Drawer } from '@mui/material';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
/**
 * # 改用 {@link FuiDrawer}
 *
 * @deprecated
 */
export const LayoutDrawer = memo(function LayoutDrawer(props) {
    const state = useSnapshot(props.store);
    return (<Drawer css={css `
        ${state.Drawer.customCSS};
      `} className={props.className} anchor={state.Drawer.position} open={state.Drawer.open} onClose={() => {
            props.store.Drawer.open = false;
        }} ModalProps={{ disablePortal: true }}>
      {props.children}
    </Drawer>);
});
