import { css } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { globalBlueGreen } from '~/modules/AppLayout/Colors';
/**
 * # 改用 {@link FuiDrawer}
 *
 * @deprecated
 */
export const LayoutDrawerToggle = memo(function LayoutDrawerToggle(props) {
    const state = useSnapshot(props.store);
    return (<MenuIcon css={css `
        color: ${globalBlueGreen.bg500};
        ${state.DrawerToggle.customCSS};
      `} className={props.className} onClick={event => {
            props.store.Drawer.open = !props.store.Drawer.open ? true : false;
        }}/>);
});
