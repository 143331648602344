import { css } from '@emotion/react';
import { Fragment, memo } from 'react';
import { useGridArea } from '~/hooks/useGridArea';
import { LayoutGlobalCSSSetup } from '~/modules/SDK/Layout/layout/LayoutGlobalCSSSetup';
import { layoutGridName } from '~/modules/SDK/Layout/layout/layoutGridName';
/**
 * 以相同邏輯內容，可建立數個語義化的 Grid Area 介面組件
 *
 * - 專為 class LayoutModule 服務
 *
 * @private SDK/layout 內部使用
 * @example
 *   // 建立同樣邏輯，與同樣 state tree，但組件名稱不同而已
 *   const Top = createLayoutGridArea({ name: 'Top', store })
 *   const Right = createLayoutGridArea({ name: 'Right', store })
 *   const Main = createLayoutGridArea({ name: 'Main', store })
 *   const Left = createLayoutGridArea({ name: 'Left', store })
 */
export function createLayoutGridArea(options) {
    const componentName = layoutGridName[options.name];
    const component = {
        [componentName](props) {
            const LayoutBlock = useGridArea(layoutGridName[options.name], { unique: true });
            return (<Fragment>
          {options.grid ? <LayoutGlobalCSSSetup /> : null}

          <LayoutBlock.Area grid={options.grid} css={css `
              ${options.store[options.name].defaultsCSS};
              ${options.store[options.name].customCSS};
            `}>
            {props.children}
          </LayoutBlock.Area>
        </Fragment>);
        },
    };
    return memo(component[componentName]);
}
