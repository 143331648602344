import React, { useMemo } from 'react';
import { _GridAreaStyled } from '~/modules/SDK/grid/createGridArea';
/** @deprecated 試試看新的這個吧 -> `createGridArea` */
export const useGridArea = (
/** The area name that apply to CSS */
name, options) => {
    const uuid = useMemo(() => options?.unique
        ? name
        : name.charAt(0).toUpperCase() +
            name.substring(1) +
            '--' +
            Math.random().toString(36).slice(2), [name, options?.unique]);
    const Area = useMemo(() => {
        function Component(props) {
            return (<_GridAreaStyled data-testid={uuid} name={uuid} {...props}>
          {props.children}
        </_GridAreaStyled>);
        }
        Component.displayName = `GridArea:${uuid}`;
        return Component;
    }, [uuid]);
    return useMemo(() => ({
        name: uuid,
        Area,
    }), [Area, uuid]);
};
