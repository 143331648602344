/* eslint-disable sort-class-members/sort-class-members */ // cuz it @deprecated
import { css } from '@emotion/react';
import { memo } from 'react';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import { createDesktopCss } from '~/css/createDesktopCss';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { createDefaultsAndCustomTypedCSS } from '~/modules/SDK/Layout/layout/createDefaultsAndCustomTypedCSS';
import { createLayoutGridArea } from '~/modules/SDK/Layout/layout/createLayoutGridArea';
import { LayoutDrawer } from '~/modules/SDK/Layout/layout/LayoutDrawer';
import { LayoutDrawerToggle } from '~/modules/SDK/Layout/layout/LayoutDrawerToggle';
import { layoutGridName } from '~/modules/SDK/Layout/layout/layoutGridName';
/**
 * # 現在應該完全改用 TemplatePage 應該沒問題了
 *
 * ## Layout Module
 *
 * @deprecated
 */
export class LayoutModule {
    store;
    /**
     * # 改用 {@link FuiDrawer}
     *
     * @deprecated
     */
    DrawerToggle;
    /**
     * - 代表版型：good178-stock
     * - Grid Element Container
     * - 須搭配並包裹 Top、Main、Right、Left 並為其自動排版外框版型
     */
    TopAndLeftMain;
    /**
     * - 代表版型：hyt888
     * - Grid Element Container
     * - 須搭配並包裹 Top、Main 並為其自動排版外框版型
     */
    TopAndMain;
    /**
     * - 代表版型：winner98、winner98_stock
     * - Grid Element Container
     * - 須搭配並包裹 Top、Main、Right、Left 並為其自動排版外框版型
     */
    TopAndRightMain;
    /**
     * - 代表版型：blue168
     * - Grid Element Container
     * - 須搭配並包裹 Top、Main、Right、Left 並為其自動排版外框版型
     */
    LeftMainRight;
    /**
     * - 代表版型：futuresai/quote
     * - Grid Element Container
     * - 須搭配並包裹 Top、Main、Right、Left 並為其自動排版外框版型
     */
    LeftRight;
    /**
     * - 代表版型：道場
     * - Grid Element Container
     * - 須搭配並包裹 Top、Main、Right、Left 並為其自動排版外框版型
     */
    TopAndMainAndFooter;
    /**
     * # 改用 {@link FuiDrawer}
     *
     * @deprecated
     */
    Drawer;
    /**
     * - Grid Area
     * - 須被 Grid Element Container 包裹
     */
    Top;
    /**
     * - Grid Area
     * - 須被 Grid Element Container 包裹
     */
    Right;
    /**
     * - Grid Area
     * - 須被 Grid Element Container 包裹
     */
    Main;
    /**
     * - Grid Area
     * - 須被 Grid Element Container 包裹
     */
    Left;
    /**
     * - Grid Area
     * - 須被 Grid Element Container 包裹
     */
    Footer;
    //
    constructor() {
        const store = (this.store = proxy({
            /** 供展開用的面版 */
            Drawer: {
                /** 展開的位置 */
                position: 'left',
                /** 是否展開狀態 */
                open: false,
                ...createDefaultsAndCustomTypedCSS(),
            },
            DrawerToggle: {
                ...createDefaultsAndCustomTypedCSS(),
            },
            LeftMainRight: {
                ...createDefaultsAndCustomTypedCSS({
                    defaultsCSS: css `
            width: 100%;
            height: 100%;
            grid-auto-rows: max-content;
            grid-template-rows: 1fr;
            grid-template-columns: 320px 1fr 320px;
            grid-template-areas: '${layoutGridName.Left} ${layoutGridName.Main} ${layoutGridName.Right}';
            justify-content: center;
            align-items: center;
            grid-gap: 4px;

            ${createMobileCss2(css `
              grid-template-rows: 42px auto;
              grid-template-columns: 1fr;
              grid-template-areas:
                '${layoutGridName.Top}'
                '${layoutGridName.Main}';
            `)}

            ${createIPadCss(css `
              grid-template-rows: 42px auto;
              grid-template-columns: 1fr;
              grid-template-areas:
                '${layoutGridName.Top}'
                '${layoutGridName.Main}';
            `)}
          `,
                }),
            },
            LeftRight: {
                ...createDefaultsAndCustomTypedCSS({
                    defaultsCSS: css `
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;

            grid-auto-rows: max-content;
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
              '${layoutGridName.Right} ${layoutGridName.Right}'
              '${layoutGridName.Right} ${layoutGridName.Right}';

            ${createDesktopCss(css `
              grid-auto-rows: max-content;
              grid-template-columns: 200px 1fr;
              grid-template-areas:
                '${layoutGridName.Left} ${layoutGridName.Right}'
                '${layoutGridName.Left} ${layoutGridName.Right}';
            `)}
          `,
                }),
            },
            TopAndMain: {
                ...createDefaultsAndCustomTypedCSS({
                    defaultsCSS: css `
            width: 100%;
            height: 100%;
            grid-auto-rows: max-content;
            grid-template-rows: 48px 1fr;
            grid-template-columns: minmax(0, 1fr);
            grid-template-areas:
              '${layoutGridName.Top} ${layoutGridName.Top}'
              '${layoutGridName.Main} ${layoutGridName.Main}'
              '${layoutGridName.Main} ${layoutGridName.Main}';
            justify-content: center;
            align-items: center;

            ${createMobileCss2(css `
              grid-template-rows: 48px auto;
              grid-template-columns: 1fr max-content;
              grid-template-areas:
                '${layoutGridName.Top}'
                '${layoutGridName.Main}';
            `)}
          `,
                }),
            },
            TopAndLeftMain: {
                ...createDefaultsAndCustomTypedCSS({
                    defaultsCSS: css `
            width: 100%;
            height: 100%;
            grid-auto-rows: max-content;
            grid-template-rows: 42px auto auto;
            grid-template-columns: 336px 1fr;
            grid-template-areas:
              '${layoutGridName.Top} ${layoutGridName.Top}'
              '${layoutGridName.Left} ${layoutGridName.Main}'
              '${layoutGridName.Left} ${layoutGridName.Main}';
            justify-content: center;
            align-items: center;
            grid-gap: 4px;

            ${createMobileCss2(css `
              grid-template-rows: 42px auto;
              grid-template-columns: 1fr max-content;
              grid-template-areas:
                '${layoutGridName.Top}'
                '${layoutGridName.Main}';
            `)}

            ${createIPadCss(css `
              grid-template-rows: 42px auto;
              grid-template-columns: 1fr max-content;
              grid-template-areas:
                '${layoutGridName.Top}'
                '${layoutGridName.Main}';
            `)}
          `,
                }),
            },
            TopAndRightMain: {
                ...createDefaultsAndCustomTypedCSS({
                    defaultsCSS: css `
            width: 100%;
            height: 100%;
            grid-auto-rows: max-content;
            grid-template-rows: 42px auto auto;
            grid-template-columns: 1fr 336px;
            grid-template-areas:
              '${layoutGridName.Top} ${layoutGridName.Top}'
              '${layoutGridName.Main} ${layoutGridName.Right}'
              '${layoutGridName.Main} ${layoutGridName.Right}';
            justify-content: center;
            align-items: center;

            ${createMobileCss2(css `
              grid-template-rows: 42px auto;
              grid-template-columns: 1fr max-content;
              grid-template-areas:
                '${layoutGridName.Top}'
                '${layoutGridName.Main}';
            `)}

            ${createIPadCss(css `
              grid-template-rows: 42px auto;
              grid-template-columns: 1fr max-content;
              grid-template-areas:
                '${layoutGridName.Top}'
                '${layoutGridName.Main}';
            `)}
          `,
                }),
            },
            TopAndMainAndFooter: {
                ...createDefaultsAndCustomTypedCSS({
                    defaultsCSS: css `
            width: 100%;
            grid-auto-rows: max-content;
            grid-template-rows: 42px auto auto;
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
              '${layoutGridName.Top} ${layoutGridName.Top}'
              '${layoutGridName.Main} ${layoutGridName.Main}'
              '${layoutGridName.Footer} ${layoutGridName.Footer}';
            justify-content: center;
            align-items: center;
          `,
                }),
            },
            Top: {
                ...createDefaultsAndCustomTypedCSS({
                    defaultsCSS: flex.h.crossCenter,
                }),
            },
            Left: {
                ...createDefaultsAndCustomTypedCSS(),
            },
            Main: {
                ...createDefaultsAndCustomTypedCSS(),
            },
            Right: {
                ...createDefaultsAndCustomTypedCSS(),
            },
            Footer: {
                ...createDefaultsAndCustomTypedCSS(),
            },
        }));
        devtools(store);
        this.DrawerToggle = memo(function Layout_DrawerToggle(props) {
            return (<LayoutDrawerToggle className={props.className} store={store}/>);
        });
        this.Drawer = memo(function Layout_Drawer(props) {
            return (<LayoutDrawer className={props.className} store={store}>
          {props.children}
        </LayoutDrawer>);
        });
        this.Top = createLayoutGridArea({ name: 'Top', store });
        this.Right = createLayoutGridArea({ name: 'Right', store });
        this.Main = createLayoutGridArea({ name: 'Main', store });
        this.Left = createLayoutGridArea({ name: 'Left', store });
        this.Footer = createLayoutGridArea({ name: 'Footer', store, grid: true });
        this.LeftMainRight = createLayoutGridArea({ name: 'LeftMainRight', store, grid: true });
        this.LeftRight = createLayoutGridArea({ name: 'LeftRight', store, grid: true });
        this.TopAndLeftMain = createLayoutGridArea({ name: 'TopAndLeftMain', store, grid: true });
        this.TopAndRightMain = createLayoutGridArea({ name: 'TopAndRightMain', store, grid: true });
        this.TopAndMain = createLayoutGridArea({ name: 'TopAndMain', store, grid: true });
        this.TopAndMainAndFooter = createLayoutGridArea({
            name: 'TopAndMainAndFooter',
            store,
            grid: true,
        });
    }
}
