export const layoutGridName = {
    LeftMainRight: 'LayoutLeftMainRight',
    LeftRight: 'LayoutLeftRight',
    TopAndMain: 'LayoutTopAndMain',
    TopAndLeftMain: 'LayoutTopAndLeftMain',
    TopAndRightMain: 'TopAndRightMain',
    TopAndMainAndFooter: 'TopAndMainAndFooter',
    Top: 'LayoutGridTop',
    Right: 'LayoutGridRight',
    Main: 'LayoutGridMain',
    Left: 'LayoutGridLeft',
    Footer: 'LayoutGridFooter',
};
